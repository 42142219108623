<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import { Gems } from '@/gems'
import { KNOLIA } from '@/knolia'

const emits = defineEmits(['toggle-childs'])

const props = defineProps({
    element: {
      type: Object,
      default: Object
    }
})

const showTrainingReqInput = ref(false)
const elementType = ref('')

function GetTrainingRequestId(id) {
    const type = props.element.is_activity ? 'activity' : props.element.is_task ? 'task' : 'skill'
    return `training-${type}-${id}`
}

async function SendTrainingRequest(id, elem) {
    const request = document.getElementById(GetTrainingRequestId(id))

    KNOLIA.CallBackEnd('post', 'set-operations&get=send-training-request',
        {
            params: {
                request: request.value,
                elem
            }
        }
    );

    request.value = null
}

function GapText(gap) {
    return gap == 0 ? '[Sem gap]' : `(${gap}%)`
}

function GapStyle(gap) {
    if(gap == 0)
        return 'div-no-gap'

    if(gap == 100)
        return 'div-full-gap'

    return ''
}

onMounted(() => {
    elementType.value = props.element.is_activity ? 'Atividade' : props.element.is_task ? 'Tarefa' : 'Competência'
})
</script>

<template>
    <div class="div-row-text"> 
        <div class="cursor-default" @mouseover="Gems.Tooltip($event.target, elementType, 'tooltip')">
            {{ element.designation }}
            <i v-if="(element.is_task || element.is_activity) && element.children.length > 0" @click="emits('toggle-childs', element)" class="fas cursor-pointer"
                :class="{ 'fa-chevron-down': !element.showChildren, 'fa-chevron-up': element.showChildren }"></i>
        </div>
    
        <div class="div-gaps-container cursor-default" v-if="element.is_activity || element.is_task">
            <div class="div-gap" :class="GapStyle(element.gap)" @mouseenter="Gems.Tooltip($event.target, 'Gap para o ideal definido', 'tooltip')">{{ GapText(element.gap) }}</div>
            <div> | </div> 
            <div class="div-min-gap" :class="GapStyle(element.min_gap)" @mouseenter="Gems.Tooltip($event.target, 'Gap para o mín. definido', 'tooltip')">{{ GapText(element.min_gap) }}</div> 
        </div>

        <div v-else>
            <strong>Classificado: </strong> [{{ element.proficiency ?? '--' }}]
        </div>
    </div>

    <div class="div-training-requests">
        <input :id="GetTrainingRequestId(element.id)" class="input-training-request" type="text"
            maxlength="255" v-if="showTrainingReqInput">
        
        <i class="fa fa-check cursor-pointer" v-if="showTrainingReqInput"
            @click="SendTrainingRequest(element.id, element)" @mouseenter="Gems.Tooltip($event.target, 'Gravar', 'tooltip')"></i>

        <i id="fa-users-rectangle" class="fas fa-users-rectangle cursor-pointer show-icon" v-if="!showTrainingReqInput"
            @click="showTrainingReqInput = !showTrainingReqInput" @mouseenter="Gems.Tooltip($event.target, 'Pedido de formação', 'tooltip')"></i>
        
        <i id="fa-xmark" class="fas fa-xmark cursor-pointer show-icon" v-if="showTrainingReqInput"
            @click="showTrainingReqInput = !showTrainingReqInput" @mouseenter="Gems.Tooltip($event.target, 'Cancelar', 'tooltip')"></i>
    </div>
</template>

<style scoped>
.div-row-text {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
}

.div-training-requests {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-training-request {
    padding: 4px 3px;
}

.div-training-requests i {
    margin-left: 10px;
    transform: scale(1.2);
}

.div-training-requests i:hover {
    color: var(--default-app-hover-color)
}

.fa-users-rectangle {
    margin-right: 10px;
}

.div-gaps-container {
 display: flex;
 flex-direction: row;
 gap: 5px;  
 justify-content: space-evenly;
}

.div-min-gap, 
.div-gap {
    width: 71px;
}

.div-gap {
    text-align: right;
}

.div-no-gap {
    color: greenyellow;
}

.div-full-gap {
    color: orangered;
}

</style>