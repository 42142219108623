<script setup>
import { defineProps } from 'vue'
import Row from './Row.vue'

const props = defineProps({
    expandedCriterias: Object,
    isActivitiesJob: Boolean
})

function ToggleChilds(parent) {
    parent.showChildren = !parent.showChildren
    parent.show = !parent.show
}
</script>

<template>
    <div class="div-job-activities">
        <div class="div-activity-title">
            <slot name="title"></slot>
        </div>

        <div v-for="activity in props.expandedCriterias" :key="activity.id" class="row">
            <div :class="{ 'div-activity': props.isActivitiesJob, 'div-extra-job': !props.isActivitiesJob }">
                <Row :element="activity" @toggle-childs="ToggleChilds($event)"/>
            </div>

            <div v-show="activity.show">
                <div class="div-task-container" v-for="task in activity.children" :key="task.id">
                    <div class="div-task">
                        <Row :element="task" @toggle-childs="ToggleChilds($event)"/>
                    </div>

                    <div class="div-skills-container" v-show="task.show">
                        <div v-for="skill in task.children" :key="skill.id">
                            <div class="div-skill">
                                <Row :element="skill" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
.div-activity-title {
    margin: 10px 0;
    font-weight: bold;
}

.div-activity {
    background-color: var(--default-app-alt-line-color);
}

.div-extra-job {
    background-color: #357ef5;
}

.div-activity,
.div-extra-job {
    margin-top: 10px;
}

.div-task,
.div-activity,
.div-extra-job,
.div-skill {
    padding: 5px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
}

.div-skill {
    background-color: var(--default-skill-color);
    padding: 5px;
    align-items: center;
}

.div-skills-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.div-skill {
    margin-left: 5px;
}

</style>