<script setup>

import { KNOLIA } from "@/knolia";
import { ref, onMounted, onUpdated } from "vue";
import { Gems } from "../gems";
import ActivitiesJobRow from "../components/ActivitiesJobRow.vue";

const subordinates = ref([])
const activities = ref([])
const activitiesToAdd = ref([])
const unratedActivities = ref([])
const subordinateActivities = ref([])
const userData = ref([])
const currentSubordinate = ref({ name: null, id: null })




async function GetSubordinateActivities(subordinateName, subordinateId) {
    currentSubordinate.value.name = subordinateName
    currentSubordinate.value.id = subordinateId

    KNOLIA.SetRequestedUserId(subordinateId)

    const response = await KNOLIA.CallBackEnd('post', 'get-subordinate-activities', { params: { user_id: subordinateId } })
    activitiesToAdd.value = response.data.activities_to_add ?? []
    subordinateActivities.value = response.data.user_activities ?? []
    unratedActivities.value = response.data.unrated_activities ?? []
    userData.value = response.data.user_data ?? []
    SetActivitiesAccess()
}

function SetActivitiesAccess() {
    activities.value.forEach(activity => {
        document.getElementById(`activity${activity.id}`).classList.remove('unavailable')
    })

    subordinateActivities.value.forEach(activity => {
        const ind = activities.value.findIndex(elem => elem.id == activity.id)
        activities.value[ind].showChildren = false

        document.getElementById(`activity${activity.id}`).classList.add('unavailable')
    })

    activitiesToAdd.value.forEach(activity => {
        const ind = activities.value.findIndex(elem => elem.id == activity.id)
        activities.value[ind].showChildren = false

        document.getElementById(`activity${activity.id}`).classList.add('unavailable')
    })

    unratedActivities.value.forEach(activity => {
        const ind = activities.value.findIndex(elem => elem.id == activity.id)
        activities.value[ind].showChildren = false

        document.getElementById(`activity${activity.id}`).classList.add('unavailable')
    })
}

async function RemoveActivityFromUser(activity){
    activity.active = false; 
    ControlActivityStatus(activity)
}

async function ControlActivityStatus(activity) {
    await KNOLIA.CallBackEnd('post', 'control-activity-status', { params: { activity } })
    GetSubordinateActivities(currentSubordinate.value.name, currentSubordinate.value.id)
}

async function SelectTaskAssignment(activity, task) {
    if(!activity.active) {
        Gems.Telegram('Habilite a atividade antes', 'tele-not-allowed')
        return;
    }

    await KNOLIA.CallBackEnd('post', 'set-operations&get=outside-job-task-participation', { params: { activity, task, currentSubordinate: currentSubordinate.value } })
}

async function SetActivityToUser(event) {
    const activity = JSON.parse(event.dataTransfer.getData('activity'))
    activity.active = true
    activity.is_function_activity = false

    ControlActivityStatus(activity)
}


onMounted(async () => {
    const responseSubordinates = await KNOLIA.CallBackEnd('get', 'get-operations', {
        params: {
            get: 'subordinates'
        }
    })
    subordinates.value = responseSubordinates.data

    const responseActivities = await KNOLIA.CallBackEnd('post', 'get-activities-list')
    activities.value = responseActivities.data
})

onUpdated(() => {
    document.querySelectorAll('.activity-status').forEach(elem => {
        Gems.Tooltip(elem, 'Ativar/Desativar atividade', 'tooltip')
    })
})

</script>

<template>
    <div class="splitter page-container">
        <div id="first" class="top">
            <div id="div-subordinates-container">
                <div id="div-subordinates-title">
                    <h2>Subordinados</h2>
                </div>

                <div class="div-column-container scrollbar">
                    <div class="div-row cursor-pointer" :class="{ 'bg-alt-line': currentSubordinate.id == subordinate.user_id }"
                        v-for="subordinate in subordinates" :key="subordinate.user_id"
                        @click="GetSubordinateActivities(subordinate.name, subordinate.user_id)">
                        <span class="line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{
                            subordinate.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div id="second" class="bottom">
            <div id="div-activity-container">
                <div id="div-activity-title">
                    <h2>Atividades [{{ currentSubordinate.name ?? 'Escolha um subordinado' }}]</h2>
                </div>

                <div class="div-column-container scrollbar" @dragenter.prevent @dragover.prevent @drop="SetActivityToUser($event)">
                    <div v-if="currentSubordinate.id">
                        <li class="job-title">Função: {{ userData?.job_desig }}</li>
                        <div v-for="activity in subordinateActivities" :key="activity.id">
                            <div class="div-row">
                                <i class="fas show-children cursor-pointer"
                                    :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"
                                    @click="activity.showChildren = !activity.showChildren"></i>
                                <label for="">{{ activity.designation }}</label>
                                <input class="activity-status cursor-pointer" type="checkbox" v-model="activity.active"
                                    @change="ControlActivityStatus(activity)">
                            </div>

                            <div v-show="activity.showChildren" class="div-task-container">
                                <div class="div-row" v-for="task in activity.children"
                                    :key="task.id">
                                    <div class="line-clamp line-clamp-1 cursor-default"
                                        @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                                    <select class="ddl-alt-padding"
                                        :value="task.participation_level" disabled>
                                        <option :value="0"> Não atribuida </option>
                                        <option v-for="assignment in task.participation_levels" :key="assignment.level"
                                            :value="assignment.level">
                                            {{ assignment.designation }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <li class="job-title">Fora do âmbito da função</li>

                        <div class="div-activity-type-title">
                            <span class="span-line"></span>
                            <span class="span-activity-type" @mouseover="Gems.Tooltip($event.target, 'Lista das atividades que o colaborador classificou e não fazem parte da função', 'tooltip')">Classificadas</span>
                            <span class="span-line"></span>
                        </div>

                        <div v-for="activity in activitiesToAdd" :key="activity.id">
                            <div class="div-row">
                                <i class="fas show-children cursor-pointer"
                                :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"
                                @click="activity.showChildren = !activity.showChildren"></i>
                                <label for="">{{ activity.designation }}</label>
                                <input class="activity-status cursor-pointer" type="checkbox" v-model="activity.active"
                                @change="ControlActivityStatus(activity)">
                            </div>

                            <div v-show="activity.showChildren" class="div-task-container">
                                <div class="div-row" v-for="task in activity.children"
                                    :key="task.id">
                                    <div class="line-clamp line-clamp-1 cursor-default"
                                        @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                                    <select class="cursor-pointer ddl-alt-padding"
                                        v-model="task.participation_level" @change="SelectTaskAssignment(activity, task)">
                                        <option :value="null"> Não atribuida </option>
                                        <option v-for="assignment in task.participation_levels" :key="assignment.level" :value="assignment.level">
                                            {{ assignment.designation }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="div-activity-type-title">
                            <span class="span-line"></span>
                            <span class="span-activity-type" @mouseover="Gems.Tooltip($event.target, 'Lista das atividades que o colaborador não classificou e não fazem parte da função', 'tooltip')" >Não-classificadas</span>
                            <span class="span-line"></span>
                        </div>

                        <div v-for="activity in unratedActivities" :key="activity.id">
                            <div class="div-row">
                                <i class="fas show-children cursor-pointer"
                                :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"
                                @click="activity.showChildren = !activity.showChildren"></i>
                                <label for="">{{ activity.designation }}</label>
                                <i v-if="!draggable" @click="RemoveActivityFromUser(activity)" class="fa fa-times cursor-pointer" ></i>
                            </div>

                            <div v-show="activity.showChildren" class="div-task-container">
                                <div class="div-row" v-for="task in activity.children"
                                    :key="task.id">
                                    <div class="line-clamp line-clamp-1 cursor-default"
                                        @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                                    <select class="cursor-pointer ddl-alt-padding"
                                        v-model="task.participation_level" @change="SelectTaskAssignment(activity, task)">
                                        <option :value="null"> Não atribuida </option>
                                        <option v-for="assignment in task.participation_levels" :key="assignment.level" :value="assignment.level">
                                            {{ assignment.designation }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="div-activity-container">
                <div id="div-activity-title">
                    <h2>Atividades (Não-classificadas) <i class="fa fa-info-circle" @mouseover="Gems.Tooltip($event.target, 'Lista das atividades para atribuir como não classificadas. A atividade ficará indisponível caso o colaborador tenha classificado-a a mesma faça parte da função atribuída.', 'tooltip')"></i></h2>
                </div>

                <div class="div-column-container scrollbar">
                    <ActivitiesJobRow :activities="activities" :draggable="true" @remove-task="RemoveTask($event)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.top,
.bottom {
    overflow-y: visible;
}

.bottom {
    display: flex;
    gap: 10px;
}

#div-subordinates-container,
#div-activity-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#div-subordinates-title h2 {
    margin-top: 0px;
}

.job-title {
    font-size: 1.2em;
    font-weight: 600;
}

.div-column-container {
    border: 1px solid;
    height: 100%;
    text-align: left;
    padding: 6px;
}

.div-row {
    padding: 5px 15px;
    align-items: center;
    border-radius: 25px;
    display: flex;
    gap: 10px;
}

.div-row:hover{
    background-color: var(--default-app-alt-line-color);
}

.div-row:not(:first-child),
.job-title:last-of-type {
    margin-top: 8px;
}

.div-activity select,
.div-task-container select {
    background-color: var(--default-app-alt-line-color);
}

.div-activity,
.div-task-container {
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.div-task-container {
    height: auto;
}

.div-activity-type-title {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: .8;
}

.span-line {
    width: 100%;
    border-bottom: 1px solid;
}

.span-activity-type {
    padding: 5px;
    cursor: default;
}
</style>