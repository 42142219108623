import { defineStore } from 'pinia';
import { Gems } from "@/gems";
import { KNOLIA } from "@/knolia";
import TreeType from "@/enums/TreeType";
import { MESSAGES } from "../../messages";

export const useTreeStore = defineStore('treeStore', {
    state: () => ({
        tree: [],
        activitiesArr: [],
        ACTIVITIES: 1,
        selectedMeta: [],
        skillsArr: [],
        SKILLS: 2,
        elemInserted: false
    }),

    getters: {
        Tree: (state) => state.tree,
        ActivitiesArr: (state) => state.activitiesArr,
        activities: (state) => state.ACTIVITIES,
        SelectedMeta: (state) => state.selectedMeta,
        SkillsArr: (state) => state.skillsArr,
        skills: (state) => state.SKILLS,
        ElemInserted: (state) => state.elemInserted,
    },

    actions: {
        async UpdateTasksTree(arr) {
            await this.GetTasksDepth();
            this.UpdateTreeElements(arr);
            this.GetActivities();
            this.GetSkills({ treeType: "tasks" });
        },

        async UpdateSkillsTree(arr) {
            await this.GetDepth();
            this.UpdateTreeElements(arr);
            this.GetSkills();
        },

        async GetSkills(params) {
            const res = await KNOLIA.CallBackEnd("get", "get-skills-list", { params });
            this.skillsArr = KNOLIA.CovertNumToBool(res.data, "included");
            this.skillsFiltered = this.skillsArr;
        },

        UpdateTreeElements(droppedElement) {
            const treeIndex = this.tree.findIndex(tree => tree.id === this.selectedMeta.id)

            if (this.tree[treeIndex] === undefined)
                return

            const currentTree = this.tree[treeIndex]

            currentTree.selected = true

            for (const [levelIndex, level] of currentTree.levels.entries()) {
                const oldLevel = this.selectedMeta.levels[levelIndex]

                if (!oldLevel || oldLevel.id !== level.id)
                    continue

                const selectedElementsMap = new Map(oldLevel.elements.map(elem => [elem.id, elem]))

                for (const elem of level.elements) {
                    const selectedElem = selectedElementsMap.get(elem.id)

                    if (selectedElem) {
                        if (elem.children_count > 0)
                            elem.selected = selectedElem.selected

                        elem.showSelf = selectedElem.showSelf

                        if (elem.selected)
                            this.showElement([elem.id, parseInt(elem.depth, 10) + 1, elem.meta_id])
                    }

                    if (droppedElement) {
                        if (elem.id === droppedElement[0]?.parent_id) elem.selected = true;
                        if (elem.parent_id === droppedElement[0]?.parent_id) elem.showSelf = true;
                    }
                }

                for (const selectedElem of oldLevel.elements) {
                    if (selectedElem.new)
                        level.elements.push(selectedElem)
                }
            }
            this.setSelectedMeta(currentTree)
        },

        async GetTasksDepth() {
            const res = await KNOLIA.CallBackEnd("get", "get-tasks-tree");
            this.tree = res.data;
        },

        async GetDepth() {
            const res = await KNOLIA.CallBackEnd("get", "get-skill-tree");
            this.tree = res.data;
        },

        async GetActivities() {
            const res = await KNOLIA.CallBackEnd("get", "get-activities-list");
            this.activitiesArr = KNOLIA.CovertNumToBool(res.data, "included");
            this.activitiesFiltered = KNOLIA.CovertNumToBool(res.data, "included");
        },

        async NewColumn(params) {
            const res = await KNOLIA.CallBackEnd("post", "new-depth", { params });

            if (res.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed');
                return;
            }

            if (params.treeType === TreeType.SKILLS) {
                await this.GetDepth();
                this.UpdateTreeElements();
                this.GetSkills();
            } else {
                await this.GetTasksDepth();
                this.UpdateTreeElements();
                this.GetActivities();
                this.GetSkills({ treeType: "tasks" });
            }
        },

        FilterChildren(arr) {
            const [elem, checked, meta_id] = arr;
            const depth = parseInt(elem.depth, 10) + 1;
            const treeIndex = this.tree.findIndex(tree => tree.elem_id === meta_id);

            for (const [levelIndex, level] of this.tree[treeIndex].levels.entries()) {
                if (level.depth === depth) {
                    if (checked) {
                        this.showElement([elem.id, depth, meta_id]);
                    } else {
                        this.HideRecursive([this.tree[treeIndex].levels, [elem.id], levelIndex]);
                    }
                }
            }
        },

        HideRecursive(arr) {
            const [level, itemArr, i] = arr;

            // Exit if we've processed all levels or itemArr is empty
            if (i >= level.length || itemArr.length === 0) return;

            const newTarget = [];
            const currentLevelElements = level[i].elements;

            // Create a Set for faster parent_id lookups
            const itemSet = new Set(itemArr);

            // Iterate through elements in the current level
            for (const element of currentLevelElements) {
                if (itemSet.has(element.parent_id)) {
                    newTarget.push(element.id);
                    element.showSelf = false;
                    element.selected = false;
                }
            }

            this.HideRecursive([level, newTarget, i + 1]);
        },

        async DissociateElement(element, treeType) {
            const res = await KNOLIA.CallBackEnd("post", "dissociate-from-tree", {
                element,
                tree: treeType == TreeType.SKILLS ? 'skills' : 'tasks'
            });

            if (res.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed");
                return;
            }

            this.removeElementAndChilds(element);

            if (treeType == TreeType.SKILLS)
                this.UpdateSkillsTree();
            else
                this.UpdateTasksTree()
        },

        async UpdateDepthName(params) {
            params.element.designation = params.name;
            const response = await KNOLIA.CallBackEnd("post", "control-tree-element", params);

            if (response.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed");
                return;
            }
        },

        SetParentNameAtFirstSibling(level, parentId) {
            let firstInd = null

            level.elements.forEach((element, ind) => {
                if (element.parent_id == parentId) {
                    element.firstSibling = false
                    if (firstInd == null) {
                        element.firstSibling = true
                        firstInd = ind
                    }
                }
            })
        },

        async UpdateElementName(params) {
            params.element.designation = params.name.trim() === '' ? '[Novo elemento]' : params.name;
            const res = await KNOLIA.CallBackEnd("post", "control-tree-element", params);

            if (res.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed");
                return;
            }

            if (params.element.new) {
                Object.assign(params.element, { id: res.data.new_id, elem_id: res.data.elem_id, new: false })
            }

            this.tree.forEach(meta => {
                if (meta.elem_id !== params.element.meta_id) return;

                meta.levels.forEach(level => {
                    if (params.element.new) {
                        if (level.depth === params.element.depth - 1) {
                            level.elements.forEach(elem => {
                                if (elem.parent_id == params.element.id) {
                                    elem.count_children++
                                    elem.selected = true
                                }
                            });
                        }
                    }

                    if (level.depth === params.element.depth) {
                        level.elements.sort((a, b) => {
                            if (!a.new && a.parent_id === params.element.parent_id)
                                return a.designation.localeCompare(b.designation)
                        })

                        this.SetParentNameAtFirstSibling(level, params.element.parent_id)

                        level.forceRenderKey += 1
                    }

                    if (level.depth === params.element.depth + 1) {
                        if (!params.element.new) {
                            level.elements.forEach(elem => {
                                if (elem.parent_id == params.element.id)
                                    elem.parent_designation = params.element.designation;
                            });
                        }
                    }
                });
            });
        },

        async DropElementInSkillsTree(event, target) {
            const newItem = JSON.parse(event.dataTransfer.getData("element"))
            newItem["selected"] = false
            newItem["showSelf"] = false

            if (newItem.fromElementList) {
                newItem["depth"] = parseInt(target.depth, 10) + 1;
                await this.AddElementFromSkillsList(newItem, target, true)
                return;
            }

            if (target.totalDepth - 2 <= 0) {
                Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
                return
            }

            if (newItem.depth < target.depth + 1 || newItem.id == target.id) {
                Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
                return
            }

            await KNOLIA.CallBackEnd("post", "update-tree-position", {
                element_id: newItem.id,
                element: newItem,
                target: target,
                to: "element",
                treeType: TreeType.SKILLS,
            }).then(() => {
                this.UpdateSkillsTree([newItem])
                Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success")
            });

            this.toggleElemInserted(true)
        },

        async DropElementInTasksTree(event, target) {
            const newItem = JSON.parse(event.dataTransfer.getData("element"))
            newItem["selected"] = false
            newItem["showSelf"] = true

            if (newItem.fromSkillsList || newItem.fromSkillsOpList) {
                this.AddElementFromSkillsList(newItem, target)
                return
            }

            if (newItem.fromElementList) {
                this.toggleElemInserted(await this.AddElementFromActivities(newItem, target))
                return
            }

            if (target.totalDepth - 2 <= 0 || newItem.depth < parseInt(target.depth, 10) + 1 || newItem.id == target.id) {
                Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
                return
            }

            await KNOLIA.CallBackEnd("post", "update-tree-position", {
                element: newItem,
                target: target,
                to: "element",
                treeType: TreeType.TASKS,
            }).then(() => {
                this.UpdateTasksTree([newItem])
                Gems.Telegram(MESSAGES.SUCCESS.CHANGED, "tele-success")
            })

            this.toggleElemInserted(true)
        },


        async AddElementFromSkillsList(newElem, target, intoSkillsTree = false) {
            let depthForSkills = target.totalDepth - 1
            newElem["parent_id"] = target.id
            newElem["depth"] = target.depth + 1

            if (intoSkillsTree)
                depthForSkills = target.totalDepth >= 2 ? target.totalDepth - 2 : 1

            if (target.depth > depthForSkills) {
                Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
                return
            }

            await KNOLIA.CallBackEnd("post", "control-tree-element", {
                element: newElem,
                parent: target,
                update: intoSkillsTree ? 'skills' : "skills-on-task",
                treeType: intoSkillsTree ? TreeType.SKILLS : TreeType.TASKS,
            }).then(() => {
                if (intoSkillsTree)
                    this.UpdateSkillsTree()
                else
                    this.UpdateTasksTree()

                Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success")
            })

            this.toggleElemInserted(true)
        },

        async AddElementFromActivities(newElem, target) {
            const checkDepth = target.totalDepth >= 2 ? target.totalDepth - 2 : 1
            newElem["parent_id"] = target.id;
            newElem["depth"] = parseInt(target.depth, 10) + 1;

            if (target.depth != checkDepth) {
                Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
                return false
            }

            await KNOLIA.CallBackEnd("post", "control-tree-element", {
                element: newElem,
                parent: target,
                treeType: TreeType.TASKS,
                update: "tasks",
            }).then(async () => {
                await this.UpdateTasksTree()
                Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success")
            });

            return true
        },

        updateNewColumn(params) {
            const treeIndex = this.tree.findIndex((tree) => tree.elem_id == params.meta_id)

            this.tree[treeIndex].levels.forEach(level => {
                let ind

                if (params.meta)
                    ind = params.meta.levels.findIndex(selLevel => selLevel.elem_id == level.elem_id)
                else
                    ind = this.selectedMeta.levels.findIndex(selLevel => selLevel.elem_id == level.elem_id)

                const selectedMeta = this.tree[treeIndex].levels[ind]

                level.elements.forEach(element => {
                    const elemInd = selectedMeta.elements.findIndex(selElem => selElem.elem_id == element.elem_id)

                    if (elemInd != -1) {
                        element.showSelf = selectedMeta.elements[elemInd].showSelf
                        element.selected = selectedMeta.elements[elemInd].selected
                    }
                })
            })

            this.selectedMeta = this.tree[treeIndex]
        },

        showElement(arr) {
            const treeIndex = this.tree.findIndex(tree => tree.elem_id == arr[2])

            const levelIndex = this.tree[treeIndex].levels.findIndex(level => level.depth == arr[1])

            if (levelIndex != -1)
                this.tree[treeIndex].levels[levelIndex].elements.forEach(element => {
                    if (element.parent_id == arr[0])
                        element.showSelf = true
                })
        },

        setSelection(arr) {
            const treeIndex = this.tree.findIndex((tree) => tree.elem_id == arr[2])

            const levelIndex = this.selectedMeta.levels.findIndex((level) => level.depth == arr[0]['depth'])

            if (levelIndex >= 0)
                this.tree[treeIndex].levels[levelIndex].elements.forEach(element => {
                    if (element.id == arr[0]["id"])
                        element.selected = arr[1]
                })
        },

        removeSkillFromList(skill) {
            for (let level of this.selectedMeta.levels) {
                for (let element in level.elements) {
                    if (element.is_skill) {
                        if (element.elem_id == skill["skill_id"])
                            level.elements.splice(element, 1)
                    }

                }
            }
        },

        insertChild(elem) {
            this.tree.forEach(col => {
                if (col.elem_id != elem.meta_id)
                    return

                col.levels.forEach(level => {
                    if (level.depth != elem.depth)
                        return

                    let ind = level.elements.findLastIndex(element => { return element.parent_id == elem.parent_id }, elem)

                    if (ind == -1) {
                        let parent_id_before
                        for (let i = 0; i < level.elements.length; i++) {
                            if (level.elements[i].parent_designation != '') {
                                if (level.elements[i].parent_designation.localeCompare(elem.parent_designation)
                                    && level.elements[i].parent_id == elem.parent_id)
                                    break

                                parent_id_before = level.elements[i].parent_id
                            }
                        }

                        ind = level.elements.findLastIndex(element => { return element.parent_id == parent_id_before }, parent_id_before)
                    }

                    level.elements.splice(ind + 1, 0, elem)

                    level.elements.sort((a, b) => {
                        if (a.parent_id == elem.parent_id)
                            return a.designation.localeCompare(b.designation) && a.id < b.id
                    })
                })

            })
        },

        removeElementAndChilds(element) {
            this.selectedMeta.levels?.forEach(level => {
                if (level.depth == element.depth - 1) {
                    const ind = level.elements.findIndex(elem => { return elem.id == element.parent_id }, element)
                    level.elements[ind].children_count--

                    if (level.elements[ind].children_count <= 0)
                        level.elements[ind].selected = !level.elements[ind].selected
                }

                if (level.depth == element.depth) {
                    const ind = level.elements.findIndex(elem => { return elem.id == element.id }, element)
                    level.elements.splice(ind, 1)
                    this.SetParentNameAtFirstSibling(level, element.parent_id)
                }

                if (level.depth == element.depth + 1)
                    level.elements = level.elements.filter(elem => elem.parent_id != element.id)
            })
        },

        async changeElementName({ id, name }) {
            for (let col in this.tree) {
                for (let level in this.tree[col]["levels"]) {
                    for (let element in this.tree[col]["levels"][level]["elements"]) {
                        if (this.tree[col]["levels"][level]['elements'][element]["id"] == id) {
                            this.tree[col]["levels"][level]['elements'][element]["designation"] = name;
                        }
                    }
                }
                if (this.tree[col]["id"] == this.selectedMeta['id']) {
                    this.selectedMeta = this.tree[col];
                }
            }
        },

        showAllElements(params) {
            const treeIndex = this.tree.findIndex((tree) => tree.elem_id == params.meta_id)

            for (let level of this.tree[treeIndex].levels) {
                if (level.depth <= params.depth) {
                    for (let element of level.elements) {
                        if (element) {
                            element.showSelf = true
                            if (level.depth < params.depth && element.children_count > 0)
                                element.selected = true
                        }
                    }
                }
            }
        },

        filterLists(type, searchText) {
            const list = type == this.ACTIVITIES ? this.activitiesArr : this.skillsArr

            const arrFilteredElem = list.filter(
                (elem) =>
                    elem.designation.trim().toUpperCase().search(searchText.trim().toUpperCase()) > -1
            )

            const arrFilteredChildren = list.filter((elem) => {
                const res = elem.children.filter(
                    (skill_op) =>
                        skill_op.designation.trim().toUpperCase()
                            .search(searchText.trim().toUpperCase()) > -1
                )

                if (res.length > 0)
                    return true
            })

            let arrFiltered = arrFilteredElem.concat(arrFilteredChildren);

            arrFiltered = arrFiltered.filter(
                (item, index) => arrFiltered.indexOf(item) === index
            );

            list.forEach((indicator) => {
                indicator.visible = "display: none";
                arrFiltered.forEach((filtered) => {
                    if (type == this.ACTIVITIES) {
                        if (indicator.id == filtered.id)
                            indicator.visible = ""
                    } else {
                        if (indicator.skill_id == filtered.skill_id)
                            indicator.visible = ""
                    }
                });
            });
        },

        setColumnsTooltip: (column) => {
            const knowledge = document.getElementById(`knowledge${column.id}`)
            const removeColIcon = document.getElementById(`remove-column${column.id}`)
            const newColumnIcon = document.getElementById(`new-column${column.id}`)
            const confirmColNameIcon = document.getElementById(`confirm-column-name-icon-${column.id}`)
            const cancelColNameIcon = document.getElementById(`cancel-column-name-icon-${column.id}`)
            const columnDepth = document.getElementById(`depth${column.depth}`)

            if (removeColIcon)
                Gems.Tooltip(removeColIcon, "Apagar nível", "tooltip")

            if (knowledge)
                Gems.Tooltip(knowledge, "Coluna dos conhecimentos", "tooltip")

            if (confirmColNameIcon)
                Gems.Tooltip(confirmColNameIcon, "Confirmar", "tooltip")

            if (cancelColNameIcon)
                Gems.Tooltip(cancelColNameIcon, "Cancelar", "tooltip")

            if (newColumnIcon)
                Gems.Tooltip(newColumnIcon, "Criar nova coluna", "tooltip")

            if (columnDepth)
                Gems.Tooltip(columnDepth, `Abrir todos os elementos até o nível ${column.depth}`, "tooltip")
        },

        setElementsTooltips: (element) => {
            const newElem = document.getElementById(`new-elem${element.id}`)
            const checkbox = document.getElementById(`checkbox${element.id}`)
            const deleteElem = document.getElementById(`delete${element.id}`)
            const confirmElemNameIcon = document.getElementById(`confirm-elem-name${element.id}`)
            const cancelElemNameIcon = document.getElementById(`cancel-elem-name${element.id}`)
            const parentName = document.getElementById(`parent-name${element.id}`)

            if (newElem)
                Gems.Tooltip(newElem, "Criar um novo elemento filho", "tooltip")

            if (deleteElem)
                Gems.Tooltip(deleteElem, "Remover elemento e todos os seus filhos da árvore", "tooltip")

            if (checkbox)
                Gems.Tooltip(checkbox, "Ver filhos", "tooltip")

            if (confirmElemNameIcon)
                Gems.Tooltip(confirmElemNameIcon, "Confirmar", "tooltip")

            if (cancelElemNameIcon)
                Gems.Tooltip(cancelElemNameIcon, "Cancelar", "tooltip")

            if (parentName)
                Gems.Tooltip(parentName, 'Pai', 'tooltip')

        },

        setSelectedMeta(arr) {
            this.selectedMeta = arr

            for (let column of this.tree) {
                if (column.id != arr?.id)
                    column.selected = false
            }
        },

        toggleElemInserted(value = false) {
            this.elemInserted = value
        },

        resetTree() {
            this.tree = []
            this.activitiesArr = []
            this.selectedMeta = []
            this.skillsArr = []
            this.elemInserted = false
        },
    }
});
