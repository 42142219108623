/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router"
import { useUserStore } from '@/store/modules/user.js'
import SkillsTree from "../views/SkillsTree.vue"
import TasksTree from "../views/TasksTree.vue"
import Privacy from "../views/Privacy.vue"
import Admin from "../views/Admin.vue"
import Import from "@/views/Import.vue"
import Home from "@/views/Home.vue"
import SearchUsers from "@/views/SearchUsers.vue"
import JobTasks from "@/views/JobTasks.vue"
import SkillsControl from "@/views/SkillsControl.vue"
import Users from "@/views/Users.vue"
import MyKnolia from "@/views/MyKnolia.vue"
import Management from "@/views/Management.vue"
import SkillCard from "@/views/SkillCard.vue"
import UserActivityControl from "@/views/UserActivityControl.vue"
import Permissions from "@/views/Permissions.vue"
import { KNOLIA } from "@/knolia"

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "/import",
        name: "Import",
        component: Import,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
        },
      },
      {
        path: "/skills-tree",
        name: "SkillsTree",
        component: SkillsTree,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
        },
      },
      {
        path: "/tasks-tree",
        name: "TasksTree",
        component: TasksTree,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
        },
      },
      {
        path: "/job-tasks",
        name: "JobTasks",
        component: JobTasks,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
        },
      },
      {
        path: "/permissions",
        name: "Permissions",
        component: Permissions,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
        },
      },
    ],
  },
  {
    path: "/my-knolia",
    name: "MyKnolia",
    component: MyKnolia,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/rank-skills",
    name: "RankSkills",
    component: SkillsControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      principalNode: "/my-knolia",
    },
  },
  {
    path: "/validate-skills",
    name: "ValidadeSkills",
    component: SkillsControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/skill-card",
    name: "SkillCard",
    component: SkillCard,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      principalNode: "/my-knolia",
    },
  },
  {
    path: "/management",
    name: "Management",
    component: Management,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/user-activity-control",
    name: "UserActivityControl",
    component: UserActivityControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      principalNode: "/management",
    },
  },
  {
    path: "/search-users",
    name: "SearchUsers",
    component: SearchUsers,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      principalNode: "/management",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


async function Authentication(authRequired, next) {
  KNOLIA.CallBackEnd("post", "router-check").then((response) => {
    if (response.error) {
      localStorage.setItem("auth", JSON.stringify({ value: false }))
      localStorage.setItem("admin", JSON.stringify({ value: 0 }))
      next("/")

      return false
    }

    const auth = { value: response.data['status'] == 5 ? true : false }
    const admin = { value: response.data['admin'] != null ? response.data['admin'] : 0 }
    const store = useUserStore()

    localStorage.setItem("auth", JSON.stringify(auth))
    localStorage.setItem("admin", JSON.stringify(admin))

    store.SetCountValidations(response.data.request_count)

    if (authRequired && !auth.value)
      next("/")
  })
}

function CheckForAdmin(adminRequired, next) {
  if (adminRequired && !KNOLIA.isAdmin()) {
    next("/rank-skills")
  } else {
    next()
  }
}

router.afterEach(() => {
  document.body.scrollTop = 0;
});

router.beforeEach(async (to, from, next) => {
  document.title = "KNOLIA"
  const store = useUserStore()
  const adminRequired = to.matched.some((route) => route.meta.requiresAdmin)
  const authRequired = to.matched.some((route) => route.meta.requiresAuth)

  switch (to.path) {
    case '/':
      let checked = await KNOLIA.CallBackEnd("get", "check-credentials")

      if (checked?.data?.status == 5 || checked?.data?.status == 1)
        next("/rank-skills")
      break;
    default:
      await store.GetUser()
      await Authentication(authRequired, next)

      if (to.path == '/validate-skills')
        Object.assign(to.params, { validateSkills: true })


      CheckForAdmin(adminRequired, next)

  }
});

export default router;