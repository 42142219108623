<script setup>
import { defineProps, defineEmits, onMounted, onUpdated, ref, computed } from "vue";
import { KNOLIA } from "../knolia";
import { Gems } from "@/gems";
import { useTreeStore } from '@/store/modules/trees.js';
import DepthElement from './DepthElement.vue';
import { MESSAGES } from "../messages";
import TreeType from "../enums/TreeType";

const props = defineProps({
  column: { type: Object, required: true },
  isKnowledge: { type: Boolean },
  treeType: { type: Number, required: true },
})

let emit = defineEmits(["filter-children"])
const store = useTreeStore()
let showOptions = ref(false)

const designation = computed({
  get() {
    return props.column.designation
  },
  set(newValue) {
    Object.assign(props.column, {designation: newValue})
  }
})

function ChangeColumnName(column) {
  store.UpdateDepthName({
    name: column.designation,
    element: column,
    update: "name",
    treeType: props.treeType,
  })
  showOptions.value = false
}

function DefineAsKnowledge(column) {
  column.is_knowledge = !column.is_knowledge

  store.SelectedMeta.levels.forEach(level => {
    if(level.id != column.id)
      level.is_knowledge = false
  })

  KNOLIA.CallBackEnd("post", "control-tree-element", {
    update: "knowledge",
    depth: column,
    treeType: props.treeType,
  }).then((res) => {
    if (res.error) {
      Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
      return
    }

    Gems.Telegram(MESSAGES.SUCCESS.CHANGED, "tele-success")

    store.Tree.forEach(meta => {
      if(meta.id == 1)
        meta.levels[0].elements = res.data.knowledge_elements
    })
  })
}

function DeleteColumn(column) {
  const treeTypeName = props.treeType === TreeType.SKILLS ? 'skills' : 'tasks'

  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_COLUMN_TREE, "tele-info", "", "tele-cancel").then(
    () => {
      KNOLIA.CallBackEnd("post", "delete-column", {
        column: column,
        tree: treeTypeName,
      }).then((res) => {
        if (res.error) {
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
          return
        } 
        Gems.Telegram(MESSAGES.SUCCESS.REMOVED, "tele-success")

        if (props.treeType === TreeType.SKILLS) {
          store.UpdateSkillsTree()
        } else {
          store.UpdateTasksTree()
        }

      });
    },
    () => {}
  );
}

function ShowOptions() {
  if(props.column.depth > props.column.totalDepth - 2 && !props.isKnowledge)
    return

  showOptions.value = true
}

onMounted(() => {
  Object.assign(props.column, { is_knowledge: KNOLIA.ToBoolean(props.column.is_knowledge)})
  store.setColumnsTooltip(props.column)
});

onUpdated(() => {
  store.setColumnsTooltip(props.column)
});
</script>

<template>
  <div class="depth-container">
    <div :class="props.treeType == TreeType.SKILLS ? 'depth-header-skills-tree' : 'depth-header'">
      <div class="div-column-icons">
        <i class="fa fa-plus-circle i-add-meta" :id="`new-column${column.id}`"
          @click="store.NewColumn({meta_id: column.meta_id, depth: column.depth, totalDepth: column.totalDepth, elements: column.elements, treeType: props.treeType})"
          v-show="!props.isKnowledge && column.depth <= column.totalDepth - 1"></i>
          
          <i class="fa fa-trash cursor-pointer" :id="`remove-column${column.id}`" @click="DeleteColumn(column)" v-show="!props.isKnowledge && column.depth <= column.totalDepth - 2"></i>
          
          <i class="fa-bookmark cursor-pointer" :class="{far: !column.is_knowledge, fas: column.is_knowledge}" v-show="props.treeType == TreeType.SKILLS && column.depth <= column.totalDepth - 2"
            :id="`knowledge${column.id}`" @click="DefineAsKnowledge(column)"></i>        
      </div>

      <div :class="props.treeType == TreeType.SKILLS ? 'depth depth-skills' : 'depth'" @click="store.showAllElements({depth: column.depth, meta_id: column.meta_id})"
        :id="`depth${column.depth}`">
        Nível {{ column.depth }}
      </div>
    </div>

    <div class="depth-second-header">
      <input
        :class="!props.isKnowledge && column.depth <= column.totalDepth - 2 ? 'col-name' : 'col-name depth-name'"
        type="text"
        :disabled="column.depth > column.totalDepth - 2 && !props.isKnowledge"
        :readonly="!showOptions"
        v-model="designation"
        :title="props.column.designation" 
        @dblclick="ShowOptions()"
        placeholder="[Nome do nível]"
      />
      <div class="div-menu-edit" v-show="showOptions">
        <i class="fa fa-check confirm" :id="`confirm-column-name-icon-${column.id}`" @click="ChangeColumnName(column)"></i>
        <i class="fa fa-times cancel" :id="`cancel-column-name-icon-${column.id}`" @click="showOptions = false"></i>
      </div>
    </div>
  </div>

  <div class="elements-container scrollbar">
    <span v-for="(element, index) in column.elements" :key="index" class="meta-comp-item" :class="{show: element.showSelf == true || column.depth == 1}">
      <DepthElement :element="element"
        :column-depth="column.totalDepth"
        :meta-id="column.meta_id"
        :depth="column"
        :index="index"
        :tree-type="props.treeType"
        :key="column.forceRenderKey"
        v-show="element.showSelf == true || props.column.depth == 1"
        @filter-children="emit('filter-children', $event)"
      />
    </span>
  </div>
</template>

<style scoped>
@import './assets/styles/trees-style.css';

.depth-skills {
  padding-right: 31%;
}
</style>
