<script setup>
import { defineProps } from "vue"
import { useTreeStore } from '@/store/modules/trees.js';
import DepthContainer from "@/components/DepthContainer";
import TreeType from "../enums/TreeType";

const props = defineProps({
  treeType: { type: Number, required: true },
})

const store = useTreeStore();

function FilterChildren(elem) {
  store.FilterChildren(elem);
}

function IsKnowledge() {
  if(props.treeType == TreeType.TASKS)
    return false

  return store.SelectedMeta.designation == 'Instrumentais'
}
</script>

<template>
  <div class="main" id="main">
    <section class="meta-comp-section" :class="{'special-container': column.depth > column.totalDepth - 2}" 
      v-for="(column, index) in store.SelectedMeta.levels" :key="index">
      <DepthContainer :column="column" :is-knowledge="IsKnowledge()" :tree-type="props.treeType" @filter-children="FilterChildren($event)" />
    </section>
  </div>
</template>

<style scoped>

.main {
  display: flex;
}

.meta-comp-section {
  padding: 5px 2px;
  max-width: 250px;
  width: 250px;
  height: 100%;
}

.special-container {
  background-color: #0000002e;
  margin: 0px 2px;
  border-radius: 4px;
  padding: 5px;
}
</style>
