import { KNOLIA } from "@/knolia"
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({ user: {}, coreInfo: {}, countValidations: 0 }),

    getters: {
        User: (state) => state.user,
        CountValidations: (state) => state.countValidations,
        Core: (state) => state.coreInfo
    },

    actions: {
        async GetUser() {
            const res = await KNOLIA.CallBackEnd("post", "get-user", { params: { self: true } })
            this.user = res.data.user
            this.coreInfo = res.data.core
        },

        resetUser() {
            this.user = {}
        },

        SetCountValidations(countValidations) {
            this.countValidations = countValidations
        },
    }
})
