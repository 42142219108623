<script setup>
import { useRouter } from "vue-router"
import { useUserStore } from '@/store/modules/user.js'


const store = useUserStore()
const router = useRouter()
</script>

<template>
  <div class="div-container">
    <div class="buttons">
        <button @click="router.push('user-activity-control')" v-if="store.User?.permissions?.is_admin || store.User?.is_headship">ATIVIDADES</button>
        <button @click="router.push('search-users')" v-if="store.User?.permissions?.is_admin || store.User?.permissions?.can_search_users">ENCONTRAR PESSOAS</button>
    </div>
  </div>
</template>

<style scoped>

  .div-container {
    margin: 10% auto;
    flex-direction: column;
    display: flex;
    width: 900px;
  }

  .buttons button {
    width: 300px;
    height: 50px;
    margin: 10px;
  }

  .buttons {
    align-self: center;
  }

</style>
