<script setup>
import { onUpdated, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { KNOLIA } from "../knolia"
import Profile from "./Profile.vue"
import { Gems } from "../gems"
import { useUserStore } from '@/store/modules/user.js'

const defaultImage = ref(require('@/images/default.png'))

const store = useUserStore()

let src = ref(String)
let router = useRouter()
let canShowProfileModal = ref(false)

async function GetCompanyLogo() {
  const response = await KNOLIA.CallBackEnd("get", "get-operations", {
    params: {
      get: "company-logo",
    },
  })

  src.value = response.data
}

function ToProfile(user) {
  KNOLIA.SetRequestedUserId(user.id)
  canShowProfileModal.value = true
}

function IsActualRoute(view){
  if(router.currentRoute.value.path === view || router.currentRoute.value.meta.principalNode === view)
    return 'active'
  
  return ''
}

function CanGoToManagement() {
  return store.User?.is_headship || store.User?.permissions?.is_admin || store.User?.permissions?.can_search_users
}

onMounted(async () => {
  GetCompanyLogo()
});

onUpdated(async () => {
  const photo = document.getElementById('section-menu')
  if(photo)
    Gems.Tooltip(photo, 'Meu perfil', 'tooltip')
})

</script>

<template>
  <div class="menu-container">
    <div class="logo-box"></div>
    <div class="entity-name">{{store.Core.entity}}</div>
    
    <div class="div-menu-logged" v-if="Object.keys(store.User).length > 0">
      <div style="display: flex; position: relative">
        <div class="section-menu" id="section-menu">
          <img id="div-logo" class="div-logo" @click="ToProfile(store.User)" :src="store.User.src" @error="$event.target.src = defaultImage">
        </div>
      </div>
      <p class="div-logged" @click="ToProfile(store.User)">{{ store.User.professional_name }}</p>
      <p class="div-job">{{ store.User.user_job }}</p>
    </div>

    <div class="menu-list" v-show="Object.keys(store.User).length > 0">
      <div @click="router.push('/admin')" class="menu-text" :class="IsActualRoute('/admin')" v-if="store.User?.permissions?.is_admin">Admin</div>
      <div @click="router.push('/management')" class="menu-text" :class="IsActualRoute('/management')" v-if="CanGoToManagement">Gerir</div>
      <div @click="router.push('/my-knolia')" class="menu-text" :class="IsActualRoute('/my-knolia')">Meu Knolia</div>
      <div @click="router.push('/users')" class="menu-text" :class="IsActualRoute('/users')">Utilizadores</div>
      <div @click="router.push('/validate-skills')" class="menu-text div-validation" :class="IsActualRoute('/validate-skills')" v-if="store.User?.permissions?.is_validator">
        Pedidos
        <div class="div-notification" v-if="store.CountValidations > 0">
          <i class="fas fa-bell icon-bell"></i>
          <div class="nofication-container">
            <p class="div-comments-count">{{ store.CountValidations }}</p>
          </div>
        </div>
      </div>
      <div @click="router.push('/privacy')" class="menu-text" :class="IsActualRoute('/privacy')">Privacidade</div>
      <div @click="KNOLIA.LogOut()" class="menu-text">Sair</div>
    </div>
    
    <div id="entity-logo" class="div-entity-logo" :style="`background-image: url(${src});`">
      <img id="img-entity-logo" :src="src"/>
    </div>
  </div>

  <Profile v-if="canShowProfileModal" @close-profile-modal="canShowProfileModal = false" @force-reload="emits('force-reload')"/>
</template>

<style>
  .active {
    color: var(--default-app-hover-color) !important;
  }
</style>

<style scoped>
.menu-container {
  background-color: #000;
  color: #fff;
  width: 17%;
}

.menu-list {
  cursor: pointer;
  margin: 23px 0 0 10px;
}

.menu-list div {
  width: 230px;
  height: 42px;
  cursor: pointer;
  font-size: 12pt;
  font-weight: bold;
  transition: all 0.3s ease;
  border-radius: 2px;
  display: flex;
}

.menu-row {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.menu-text {
  align-self: center;
  margin-left: 11px;
}

.logo-box {
  background-image: url("../images/logo.png");
  height: 112px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 4% 6% 0% 4%;
}

.entity-name {
  text-transform: uppercase;
  color: var(--default-app-hover-color);
  margin: -15px 0 5px 20px;
  font-size: .9rem;
  font-weight: 600;
}

.logout {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transform: scaleX(-1);
}

.div-menu-text-container {
  display: flex;
  align-items: center;
}

.div-notification-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.644);
  color: white;
  position: relative;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: solid .5px rgba(245, 245, 245, 0.568);
  font-size: .7em;
}

.section-menu {
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
}

.div-logo {
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  transition: 0.5s ease;
  box-shadow: 0 3px 4px rgb(0 0 0 / 10%), 0 4px 10px rgb(0 0 0 / 20%);
  object-fit: cover;
}

.div-logged {
  text-align: center;
  font-weight: 600;
  font-size: 11pt;
  margin-top: 3px;
  cursor: pointer;
}

.menu-text:hover,
.div-logged:hover,
.menu-row:hover {
  color: var(--default-app-hover-color);
}

.div-job {
  font-size: 10pt;
  font-weight: 150;
  margin-top: 3px;
  margin-bottom: 15px;
  text-align: center;
  color: #e4e4e4;
}

.div-entity-logo {
  width: 100%;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  max-height: 200px;
}

.div-entity-logo>img {
  width: 100%;
  visibility: hidden;
}

.div-validation {
  display: flex;
}

.nofication-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -3px;
  left: 10px;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 0.6em;
  text-align: center;
}

.icon-bell {
  font-size: 12pt;
}

.div-notification {
  display: none;
  position: relative;
  margin-left: 10px;
}

.div-comments-count {
  padding: 0;
  font-size: 7pt;
  margin: 0 auto;
  top: 0;
  bottom: 0;
}

</style>